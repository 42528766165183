import api from "../../../../../shared/api"
import authHelper from "../../../../../utils/authHelper"
import jsonParse from "./jsonParse"
import normalizeZoom from "./normalizeZoom"
import pointInRectangle from "./pointInRectangle"

const mainLoop = (
    roomId,
    cursorHeight,
    resizeThreshold,

    elementsInit, 
    currentMousePosition, 
    zoom, 
    clicked,
    instrument,
    isLoading,
    startMovebleElementsCoords,
    startMovePoint,
    startResizingElementsSize,
    curveState,

    setCurveState,
    setIsLoading,
    setElements,
    setInstrumentState,

    penColor,
    markerColor,

    centerCoords,
    setCenterCoords,

    setHistory,
    setReverseHistory,

    setSelectedAreaData,
    startSelectPoint
) => {
    let elements = elementsInit.map(x => {return {...x, data: jsonParse(x.data)}})
    let current = {
        x: currentMousePosition.x - 2*centerCoords.x,
        y: currentMousePosition.y - 2*centerCoords.y,
    }
    let normalizedZoom = normalizeZoom(zoom)

    if(clicked){
        if (instrument == 'pen' || instrument == 'marker') {
            setCurveState(c => {
                let temp = c.map(x=>x)
                let newVar = {
                    x: current.x / normalizedZoom,
                    y: current.y / normalizedZoom
                };
                temp.push(newVar)
                return temp
            })
        }
        else if (instrument === 'eraser') {
            let eraserSize = 30
            let elementsForDelete = elements
                                    .filter(el => {
                                        if (el.type === 0 || el.type === 2) {
                                                if (jsonParse(el.data.curve).filter(point => 
                                                    pointInRectangle(
                                                        {x: point.x, y: point.y},
                                                        {
                                                            x: current.x/normalizedZoom,
                                                            y: current.y/normalizedZoom,
                                                            width: eraserSize,
                                                            height: eraserSize,
                                                        },
                                                        normalizedZoom,
                                                        false
                                                    )
                                                ).length > 0) return true
                                        } else if (el.type === 1 || el.type === 3) {
                                            if (pointInRectangle(
                                                {x: current.x, y: current.y},
                                                {
                                                    x: el.data.x,
                                                    y: el.data.y,
                                                    width: el.data.width,
                                                    height: el.data.height,
                                                },
                                                normalizedZoom
                                            )) return true
                                        }
                                        return false
                                    })
            
            let elementsForDeleteIds = elementsForDelete.map(x=>x.id)

            if(elementsForDeleteIds.length > 0 && !isLoading){
                setIsLoading(true)
                api
                .deleteBoardElements(authHelper.getUserId(), roomId, elementsForDeleteIds)
                .then(resp => {
                    setIsLoading(false)
                    setElements(elements => {
                        let temp = elements?.map(x=>x)
                        temp = temp.filter(x=> !elementsForDeleteIds.includes(x.id))
                        return temp
                    })
                    elementsForDelete.map(el => setHistory(h=>[...h, {action: 'delete', elements: [el]}]))
                    setReverseHistory([])
                })    
            }
        }
    }
    if(!clicked && curveState.length > 0 && !isLoading){
        setIsLoading(true)
        let type = 0 
        switch(instrument) {
            case 'pen': 
                type = 0 
                break
            case 'marker': 
                type = 2 
                break
            default:
                return null
        }
        api
        .createBoardElement(authHelper.getUserId(), roomId, type, {
            curve: JSON.stringify(curveState), 
            color: instrument === 'pen'? penColor: markerColor
        })
        .then(elementId => {
            setElements(elements => {
                let temp = elements?.map(x=>x)
                let tempElement = {id: elementId, type: type, 
                    data: JSON.stringify(
                        {
                            curve: JSON.stringify(curveState), 
                            color: instrument === 'pen'? penColor: markerColor
                        })}
                temp.push(tempElement)
                setHistory(history => [...history, {
                    action: 'create',
                    elements: [tempElement]
                }])
                setReverseHistory([])
                return temp
            })
            setCurveState([])
            setIsLoading(false)
        })
    }

    if (instrument === 'selector' || instrument === 'notes') {
        const currentY = current.y - cursorHeight
        const hoveredElements = []
        let selectedElements = elements.filter(x => x.selected)
        let el_id = null
        let data = null
        let newInstrumentState = null

        let selectedAreaWidth = 0
        let selectedAreaHeight = 0
        let selectedAreaX = 0
        let selectedAreaY = 0

        if (startSelectPoint) {
            selectedAreaWidth = current.x - startSelectPoint.x
            selectedAreaHeight = current.y - startSelectPoint.y - cursorHeight
            selectedAreaX = selectedAreaWidth > 0 ? startSelectPoint.x: startSelectPoint.x + selectedAreaWidth 
            selectedAreaY = selectedAreaHeight > 0 ? startSelectPoint.y: startSelectPoint.y + selectedAreaHeight 
            selectedAreaWidth = Math.abs(selectedAreaWidth)
            selectedAreaHeight = Math.abs(selectedAreaHeight)
        }
        
        elements?.forEach(el => {
            let curve = null
            try {curve = jsonParse(el.data.curve)} catch{}
            if (instrument === 'selector' && curve && curve.filter(point => 
                pointInRectangle(
                    {x: current.x, y: currentY}, 
                    {x: point.x-10, y: point.y-10, width: 20, height: 20}, 
                    normalizedZoom
                )).length > 0) {
                    el_id = el.id
                    newInstrumentState = 'scalable'
                }
            if (pointInRectangle(
                {x: current.x, y: currentY}, 
                {x: el.data.x, y: el.data.y, width: el.data.width, height: el.data.height}, 
                normalizedZoom)) {
                    el_id = el.id
                    if (instrument === 'selector') {
                        newInstrumentState = 'scalable'
                    }
                    else if (instrument === 'notes' && el.type === 3) newInstrumentState = 'text'  
                }

            if((pointInRectangle(
                {x: current.x, y: currentY}, 
                {x: el.data.x + el.data.width - resizeThreshold/2, y: el.data.y + el.data.height - resizeThreshold/2, width: resizeThreshold, height: resizeThreshold}, 
                normalizedZoom)) || startResizingElementsSize)
                {
                    newInstrumentState = 'resize'
                }

            if (el.selected) {
                el_id = el.id 
                data = el.data
            }

            // элементы внутри выделенной области становятся выделенными
            if(pointInRectangle(
                {x: el.data.x, y: el.data.y}, 
                {x: selectedAreaX, y: selectedAreaY, width: selectedAreaWidth, height: selectedAreaHeight}, 
                normalizedZoom) || (
                    curve && curve.filter(point => 
                        pointInRectangle(
                            {x: point.x, y: point.y}, 
                            {x: selectedAreaX, y: selectedAreaY, width: selectedAreaWidth, height: selectedAreaHeight}, 
                            normalizedZoom
                        )).length > 0
                )) {
                    hoveredElements.push(el)
            }
        })

        if((startMovePoint != null || startResizingElementsSize != null)) {
            let diffX = (startMovePoint.x - current.x) / normalizedZoom
            let diffY = (startMovePoint.y - current.y) / normalizedZoom
            if (startResizingElementsSize != null) {
                data.width = startResizingElementsSize[0].width - diffX
                data.height = startResizingElementsSize[0].height - diffY
            } else
                selectedElements = selectedElements.map(el => {
                    let curve = null
                    try {curve = jsonParse(el.data.curve)} catch{}

                    let temp = {}
                    if (curve) temp = {
                        ...el.data,
                        curve: JSON.stringify(curve.map((point, index) => {return ({
                            x: startMovebleElementsCoords.filter(x => x.id === el.id)[0].curve[index].x - diffX, 
                            y: startMovebleElementsCoords.filter(x => x.id === el.id)[0].curve[index].y - diffY
                        })}))

                    }; else temp = {
                        ...el.data,
                        x: startMovebleElementsCoords.filter(x => x.id === el.id)[0].x - diffX, 
                        y: startMovebleElementsCoords.filter(x => x.id === el.id)[0].y - diffY
                    }

                    newInstrumentState = 'move'
                    return {...el, data: temp}
                })
        }

        if(instrument === 'selector' && !newInstrumentState && clicked) {
            setSelectedAreaData({
                x: selectedAreaX,
                y: selectedAreaY,
                width: selectedAreaWidth,
                height: selectedAreaHeight
            })
        }
        if (hoveredElements.length !== 0) setElements(elements => {return elements.map(elItem => {
            return {   
                ...elItem,
                hover: hoveredElements.map(x => x.id).indexOf(elItem.id) !== -1,
                selected: false
            };
        })});
        else {
            setElements(elements => {return elements.map(elItem => {
                return {   
                    ...elItem, 
                    data: selectedElements.map(x=>x.id).indexOf(elItem.id) !== -1? JSON.stringify(selectedElements.filter(x=>x.id===elItem.id)[0].data): 
                        elItem.data,
                    hover: elItem.id === el_id && !elItem.selected
                };
            })});
        } 
        if (!newInstrumentState && startMovePoint !== null) {
            newInstrumentState = 'move'
            
            let diffX = (startMovePoint.x - current.x)
            let diffY = (startMovePoint.y - current.y)
            
            diffX = normalizedZoom > 1? diffX / normalizedZoom: diffX * normalizedZoom
            diffY = normalizedZoom > 1? diffY / normalizedZoom: diffY * normalizedZoom
            
            setCenterCoords({
                x: centerCoords.x - diffX,
                y: centerCoords.y - diffY
            })
        } 

        setInstrumentState(newInstrumentState)
    }
}

export default mainLoop;