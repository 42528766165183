import { useContext, useEffect, useState } from 'react';
import './UsersPanel.scss'
import Chat from '../Chat/Chat';
import WsContext from '../../../../WsContext';
import authHelper from '../../../../utils/authHelper';
import toast from '../../../../utils/toast';

const UsersPanel = ({users, setUsers}) => {
    const [chat, setChat] = useState(false)
    const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0)

    const wsMessage = useContext(WsContext)
    useEffect(() => {
        if(wsMessage?.type === 'change_audio_video_state'){
            setUsers(old_users => 
                old_users.map(user => {
                    if (user.id === wsMessage.userId) {
                        user.audio = wsMessage.audioState
                        user.video = wsMessage.videoState
                    }
                    return user
                })
            )
        }
        if(wsMessage?.type == 'new_message'){
            if (!chat && wsMessage.message) toast.success(`${wsMessage.userName}: ${wsMessage.message}`)
            setUnreadMessagesNumber(unreadMessagesNumber+1)
        }
    }, [wsMessage])

    useEffect(() => {
        for (let i=0; i<users.length; i++) {
            let video = document.getElementById(`video_element_${users[i].id}`)
            video.volume = users[i].audio === false ? 0 : 1
            video.muted = users[i].id === authHelper.getUserId()
        }
    }, [users])

    return (
        <div className={`users-panel ${chat ? 'chat': ''}`}>
            {/* <div className='expand icon' /> */}
            <div 
            onWheel={e => {
                e.stopPropagation()
                if (chat) {
                    e.preventDefault()
                    e.currentTarget.scrollBy({  
                        left: e.deltaY,  
                        behavior: 'smooth', 
                    })
                }
            }}
            className='users'>
                {
                    users.map(user => 
                    <div key={`video_element_container_${user.id}`}>
                        <div className='user-video-frame'>
                            <video style={{display: user.video? 'block': 'none'}} id={`video_element_${user.id}`} autoplay="autoplay" autoPlay />
                            {!user.video && <div className='no-camera' />}
                            <div className='user-name'>
                                {!user.audio && <div className='no-audio icon' /> }
                                <div className='text'>{user.name}</div>
                            </div>
                        </div>
                    </div>
                    )
                }
            </div>

            {
                chat && <Chat setUnreadMessagesNumber={setUnreadMessagesNumber}/>
            }

            {
                chat?
                <p onClick={() => setChat(false)}>Свернуть чат</p>:
                <div className='chat-line'>
                    <div className='chat icon' />
                    {unreadMessagesNumber != 0 && <div className='unread-messages'>{unreadMessagesNumber}</div>}
                    <p onClick={() => setChat(true)}>Открыть чат</p>
                </div>
            }
        </div>
    )
};

export default UsersPanel;