import './MobilePage.scss'

const MobilePage = () => {
    return (
        <div className='mobile'>
        <div className='welcome-message'>
            <div className='logo' />
            <h1>Beresta</h1>
            <p>Бесплатный сервис для проведения занятий между преподавателем и учениками.</p>
            <p>
                Пока приложение оптимизировано только для ПК и ноутбуков. 
                Мы работаем над его улучшениями и скоро планируем 
                добавить поддержку мобильных устройств.
            </p>
            <a 
            style={{marginTop: '15px'}}
            href='mailto:info@beresta.link'>info@beresta.link</a>
        </div>
    </div>
    )
}

export default MobilePage;