import { useContext, useEffect, useRef, useState } from 'react';
import './Chat.scss'
import api from '../../../../shared/api';
import authHelper from '../../../../utils/authHelper';
import WsContext from '../../../../WsContext';
import backHelper from '../../../../utils/backHelper';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Chat = ({setUnreadMessagesNumber}) => {
    let { id } = useParams();
    const [messages, setMessages] = useState([])
    const [messageForSend, setMessageForSend] = useState('')
    const [attachFiles, setAttachFiles] = useState([])
    const refFileInput = useRef()
    const messagesElementRef = useRef()
    const wsMessage = useContext(WsContext)

    const load = () => {
        api
        .getMessages(id, authHelper.getUserId())
        .then(resp => {
            setMessages(resp)
            setTimeout(()=>{
                messagesElementRef.current.scrollTop = messagesElementRef.current.scrollHeight
            }, 100)
            setUnreadMessagesNumber(0)
        })
    }
    useEffect(()=>{
        load()
    }, [])

    useEffect(() => {
        if(wsMessage?.type == 'new_message'){
            load()
        }
    }, [wsMessage])

    const send = () => {
        if (messageForSend || attachFiles.length != 0)
        api.sentMessage(id, authHelper.getUserId(), messageForSend)
        .then(resp => {
            setMessageForSend('')
            setAttachFiles([])
            load()
        })
    }

    useEffect(() => load(), [attachFiles])

    const onAttachFiles = (event)=>{
        let file = event.target.files[0]
        api
        .uploadFile(authHelper.getUserId(), id, file.name, file)
        .then(resp => {
            setAttachFiles(old => [...old, resp])
            event.target.value = null
        })
    }
    return (
        <div className='chat-container'>
            <div className='messages' onWheel={e => e.stopPropagation()} ref={messagesElementRef}>
                {messages?.map(message => <>
                    {
                        (message.text || message.files.length != 0) && 
                        <div style={{justifyContent: message.itsYou? 'end': 'start'}} className='message-container'>
                            <div className='message'>
                                <b>{message.user}</b> <br />
                                {message.text}
                                <div className='send-at'>  
                                    {(() => {  
                                        const sendAt = moment(message.sendAt)   
                                        const format = sendAt.isSame(moment(), 'day') ? 'HH:mm' : 'DD.MM.YYYY HH:mm'  
                                        return <>{sendAt.format(format)}</>  
                                    })()}  
                                </div>  
                                {message.files?.map(file=>
                                    <div 
                                    className='file-message' 
                                    onClick={()=>{window.open(`${backHelper()}api/file/download?id=${file.id}&fileName=${file.name}`, '_blank')}}
                                    >
                                        <div className='file icon' />
                                        <div className='info'>
                                            <div className='name'>{file.name}</div>
                                            <div className='size'>{file.size} Кб</div>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    }
                </>)}
            </div>
            <div className='send-message-form'>
                <textarea 
                value={messageForSend}
                onChange={e => {setMessageForSend(e.target.value)}}
                onKeyDown={e=>{
                    if(e.key == 'Enter'){
                        send()
                    }
                }}
                /> 
                <div onClick={send} className='send icon' />
                <input ref={refFileInput} className='attach-file icon' type='file' style={{ opacity: 0 }} onChange={onAttachFiles}/>
                <div className='attach-file icon' onClick={()=>{refFileInput.current.click()}}/>
            </div>
        </div>
    )
}

export default Chat;