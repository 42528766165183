export const penColors = ['black', 'red', '#EA580C', '#FDE047', '#65A30D', '#06B6D4', '#2563EB', '#9333EA']
export const markerColors = [  
    'rgba(255, 0, 0, 0.5)',     // red  
    'rgba(234, 88, 12, 0.5)',   // #EA580C  
    'rgba(253, 224, 71, 0.5)',  // #FDE047  
    'rgba(101, 163, 13, 0.5)',  // #65A30D  
    'rgba(6, 182, 212, 0.5)',    // #06B6D4  
    'rgba(37, 99, 235, 0.5)',    // #2563EB  
    'rgba(147, 51, 234, 0.5)'    // #9333EA  
]
export const noteColors = ['#FDE68A', '#BBF7D0', '#E0C3FC']