// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../../public/fonts/Comic Sans MS.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "ComicSansMS";
  color: #454545;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-style: normal;
  font-weight: normal;
}
.selected-container {
  position: absolute;
  border: solid 1px #0369A1;
}`, "",{"version":3,"sources":["webpack://./src/shared/variables.scss","webpack://./src/pages/BoardPage/components/WhiteBoard/components/SvgFromPoint/SvgFromPoint.scss"],"names":[],"mappings":"AA4BA;EAJI,0BAAA;EACA,cAZS;EAiBT,+DAAA;EACA,kBAAA;EACA,mBAAA;AC1BJ;AAJA;EACI,kBAAA;EACA,yBAAA;AAMJ","sourcesContent":["$modal-background: rgba(0, 0, 0, 0.7);\n$border: #0000001f;\n$toast-success: rgb(72, 186, 72);\n$toast-success-hover: rgb(111, 216, 111);\n$toast-error: rgb(165, 79, 79);\n$toast-error-hover: rgb(179, 139, 139);\n\n$new-black: #7B7B7B;\n$border: #E0F2FE;\n$inner: #F0F9FF;\n$backlight: #0369A1;\n$backlight-transparent: hsla(201, 96%, 32%, 0.2);\n$backlight-shadow: rgba(3, 106, 161, 0.25);\n$text-color: #454545;\n$gray: #A8A7A7;\n$gray-transparent: rgba(69, 69, 69, 0.5);\n\n$loader-color: #000000;\n\n// styles\n$gradient: linear-gradient(90.35deg, #0369A1 2.4%, #0EA5E9 105.25%);\n\n// fonts \n@mixin font {\n    font-family: 'ComicSansMS';\n    color: $text-color;\n}\n\n@font-face {\n    @include font;\n    src: url('../../public/fonts/Comic Sans MS.ttf') format('truetype'); \n    font-style: normal; \n    font-weight: normal; \n} \n\n@mixin h1 {  \n    font-size: 24px;  \n    font-weight: bold;  \n}\n\n@mixin big-text {  \n    font-size: 20px; \n}  \n\n@mixin text {  \n    font-size: 16px;\n} \n\n@mixin middle-text {  \n    font-size: 14px;\n}\n\n@mixin small-text {  \n    font-size: 12px;\n}\n\n@mixin very-small-text {  \n    font-size: 10px;\n}\n  ","@import '../../../../../../shared/variables.scss';\n\n.selected-container {\n    position: absolute;\n    border: solid 1px $backlight\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
