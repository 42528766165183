import backHelper from "../utils/backHelper"

const baseUrl = backHelper()

const api = () => {
    const request = (method, url, data, isBlob = false, isFile = false) => {
        const headers = {}
        
        let newLocal
        if(headers != null){
            if(method == 'post' && !isFile){
                if (data?.toString() !== '[object FormData]') 
                    headers['Content-Type'] = 'application/json'
                
                headers.accept = 'application/json'
            }
            newLocal = {
                method: method,
                body: data ? data : null,
                headers: headers
            }
        }
        else
            newLocal = {
                method: method,
                body: data ? data : null,
            }  
                  
        return fetch(`${baseUrl}${url}`, newLocal)
        .then(resp => {
            if (resp.status !== 200) {
                if(resp.status === 401) window.location = '/login'
                return { isSuccess: false }
            }
            if(isBlob)
                return resp.blob()
            return resp.json()
        })
    }

    const requestPost = (url, data, isBlob = false) => {
        return request('post', url, data, isBlob)
    }
    const postFile = (url, file) => {
        return request('post', url, file, false, true)
    }
    const requestGet = (url, isBlob = false) => {
        return request('get', url, null, isBlob)
    }

    const requestPut = (url, data) => {
        return request('put', url, data)
    }

    const requestDelete = (url, data) => {
        return request('delete', url, data)
    }



    return {
        createUser: () => {
            return requestPost(`api/users/create`)
        },
        getUserInfoById: (id) => {
            return requestGet(`api/users/getById?userId=${id}`)
        },
        updateUser: (id, name) => {
            return requestPost(`api/users/update?userId=${id}&name=${name}`)
        },
        createRoom: (userId) => {
            return requestPost(`api/rooms/create?userId=${userId}`)
        },
        addUserToRoom: (userId, roomId) => {
            return requestPost(`api/rooms/addUser?userId=${userId}&roomId=${roomId}`)
        },
        getRoomInfo: (roomId) => {
            return requestGet(`api/rooms/info?roomId=${roomId}`)
        },
        setUserActive: (roomId, userId) => {
            return requestGet(`api/rooms/pingActive?userId=${userId}&roomId=${roomId}`)
        },
        sentMessage: (roomId, userId, message) => {
            return requestPost(`api/chat/sendMessage?userId=${userId}&roomId=${roomId}&message=${message}`)
        },
        getMessages: (roomId, myId) => {
            return requestGet(`api/chat/getMessages?roomId=${roomId}&me=${myId}`)
        },
        getBoardElementList: (roomId) => {
            return requestGet(`api/board/getElements?roomId=${roomId}`)
        },
        createBoardElement: (userId, roomId, type, data, id='') => {
            return requestPost(`api/board/createElement`, JSON.stringify({
                userId: userId,
                roomId: roomId,
                type: type,
                data: typeof data == 'string'? data: JSON.stringify(data),
                id: id
            }))
        },
        deleteBoardElements: (userId, roomId, ids) => {
            return requestPost(`api/board/deleteElements`, JSON.stringify({
                userId: userId,
                roomId: roomId,
                ids: ids
            }))
        },
        updateBoardElement: (elementId, data, userId) => {
            return requestPost(`api/board/updateElement`, JSON.stringify({
                id: elementId,
                data: data,
                userId: userId
            }))
        },
        clearBoard: (userId, roomId) => {
            return requestPost(`api/board/clear?roomId=${roomId}&userId=${userId}`)
        },
        uploadFile: (userId, roomId, fileName, file, x=null, y=null) => {
            return postFile(`api/file/upload?userId=${userId}&roomId=${roomId}&x=${x}&y=${y}&fileName=${fileName}`, file)
        },

        changeElementsOrder: (userId, elId) => {
            return requestPost(`api/board/changeElementsOrder?userId=${userId}&elId=${elId}`)
        },
        changeAudioVideoState: (userId, roomId, audioState, videoState) => {
            return requestPost(`api/rooms/changeAudioVideoState?userId=${userId}&roomId=${roomId}&audioState=${audioState}&videoState=${videoState}`)
        },
    }

}


export default api();
export const apiBaseUrl = baseUrl