import { markerColors, penColors } from '../colors';
import './SvgFromPoint.scss'

const hoverColor = '#0369a033'
const selectedColor = '#0369A1'

const BezierCurve = ({ points, colorIndex=0, opacity, strokeWidth=2, instrument, hover, selected }) => {
    const d = points.reduce((acc, point, index) => {
        if (index === 0) {
            return `M ${point.x} ${point.y}`;
        }

        const prevPoint = points[index - 1];

        const x1 = (2 * point.x + prevPoint.x) / 3;
        const y1 = (2 * point.y + prevPoint.y) / 3;
        const x2 = (point.x + 2 * prevPoint.x) / 3;
        const y2 = (point.y + 2 * prevPoint.y) / 3;

        return `${acc} C ${x1} ${y1}, ${x2} ${y2}, ${point.x} ${point.y}`;
    }, '');

    let topLeft = { x: null, y: null };  
    let bottomRight = { x: null, y: null };  
    
    points.forEach(point => {  
        if (point.x < topLeft.x || (point.x === topLeft.x && point.y < topLeft.y)) {  
            topLeft = point;  
        }  
        
        if (point.x > bottomRight.x || (point.x === bottomRight.x && point.y > bottomRight.y)) {  
            bottomRight = point;  
        }  
    });  

    let width = bottomRight.x-topLeft.x
    let height = topLeft.y-bottomRight.y

    return <>
    {selected && <div 
    style={{
        top: topLeft.y,
        left: topLeft.x,
        width: width,
        height: height,
    }}
    className='selected-container' />}
    <path d={d} stroke={
        hover? hoverColor: selected? selectedColor:
        instrument=='pen'? penColors[colorIndex]: markerColors[colorIndex]
    } opacity={opacity} fill="none" strokeWidth={strokeWidth} strokeLinecap="round"/></>;
};

const SvgFromPoint = ({ pointsData, colorIndex=0, strokeWidth=2, opacity=1, windowSize, zoom, centerCoords, instrument='pen', hover=false, selected=false}) => {
    if(pointsData.length == 0) return ''
    return <svg 
    style={{
        position: 'absolute', 
        top: `${(pointsData[0].y) * zoom   + windowSize.height/2 + centerCoords.y}px`, 
        left: `${(pointsData[0].x)* zoom + windowSize.width/2 + centerCoords.x}px`,
        overflow: 'visible'
    }}>
        <BezierCurve 
        colorIndex={colorIndex} 
        instrument={instrument}
        opacity={opacity} strokeWidth={strokeWidth} 
        hover={hover}
        selected={selected}
        points={pointsData.map(z=> {return {x: (z.x - pointsData[0].x) * zoom, y: (z.y - pointsData[0].y) * zoom}})} />
    </svg>
};

export default SvgFromPoint;