// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/icons/copy.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-button-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
}
.name-button-container .custom-input {
  margin-right: 15px;
}

.copy {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover !important;
  background-position-x: 0 !important;
}
.copy:hover {
  background-position-x: -30px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/BoardPage/components/InfoRoomModal/InfoRoomModal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AACI;EACI,kBAAA;AACR;;AAGA;EACI,mDAAA;EACA,iCAAA;EACA,mCAAA;AAAJ;AAEI;EACI,uCAAA;AAAR","sourcesContent":[".name-button-container {\n    display: grid;\n    grid-template-columns: 2fr 1fr;\n    align-items: end;\n\n    .custom-input {\n        margin-right: 15px;\n    }\n}\n\n.copy {\n    background: url('../../../../../public/icons/copy.svg');\n    background-size: cover !important;\n    background-position-x: 0 !important;\n\n    &:hover {\n        background-position-x: -30px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
