import { useEffect, useState } from 'react';
import './InfoRoomModal.scss'
import Modal from '../../../../shared/Modal/Modal';
import toast from '../../../../utils/toast';
import api from '../../../../shared/api';
import authHelper from '../../../../utils/authHelper';
import CustomInput from '../../../../shared/CustomInput/CustomInput';
import { useParams } from 'react-router-dom';

const InfoRoomModal = ({open, setOpen, setUsers}) => {
    let { id } = useParams();
    const [name, setName] = useState('')
    const [newName, setNewName] = useState('')
    
    const save = () => {
        if (newName !== name)
            api
            .updateUser(authHelper.getUserId(), newName)
            .then(resp => {
                toast.success(`Ага, запомнили, ${newName}`)
                setUsers(old_users => 
                    old_users.map(user => {
                        if (user.id === authHelper.getUserId()) {
                            user.name = newName
                        }
                        return user
                    })
                )
            })
        localStorage.setItem(`userCloseWelcomeModal_${id}`, true)
        setOpen(false)
    }

    useEffect(() => {
        api
        .getUserInfoById(authHelper.getUserId())
        .then(resp => {
            setName(resp.name)
            setNewName(resp.name)
        })
    }, [open])

    return ( 
        <Modal 
        header={`Настройки`}
        width={800}
        open={open}
        setOpen={setOpen}
        >   
            <CustomInput
            label='Добро пожаловать на онлайн урок! Вы можете пригласить на него людей, отправив им ссылку:'
            disabled
            value={window.location.href}
            icon={<div className='copy icon' />}
            copy
            />
            
            <div className='name-button-container'>
                <CustomInput label='Ваше имя:' value={newName} setValue={setNewName} />
                <button disabled={newName === name} onClick={save}>Сохранить</button>
            </div>
        </Modal>
    )
}

export default InfoRoomModal;