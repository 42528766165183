import './ConfirmBoardClearingModal.scss'
import Modal from '../../../../../../shared/Modal/Modal'
import api from '../../../../../../shared/api'
import authHelper from '../../../../../../utils/authHelper'

const ConfirmBoardClearingModal = ({open, setOpen, roomId, setElements, setCleaningBoardToHistory}) => {
    const clearBoard = () => {
        api
        .clearBoard(authHelper.getUserId(), roomId)
        .then(() => {
            setElements([])
            setOpen(false)
            setCleaningBoardToHistory()
        })
    }

    return (
        <Modal
        header={'Подтверждение'}
        width={500}
        open={open}
        setOpen={setOpen}
        buttons={[
            <button className='basic' style={{marginRight: '15px'}} onClick={clearBoard}>Да</button>,
            <button onClick={() => setOpen(false)}>Нет</button>
        ]}
        >
            Вы уверены что хотите очистить содержимое доски? 
        </Modal>
    )
}

export default ConfirmBoardClearingModal;