import LoaderComponent from '../LoaderComponent/LoaderComponent'
import './Loader.scss'

const Loader = () => {
    return (
        <div className='lds-roller-wrapper'>
            <LoaderComponent />
        </div>
    )
}

export default Loader