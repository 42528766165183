import { useContext, useEffect, useState } from 'react';
import './BoardPage.scss'
import WhiteBoard from './components/WhiteBoard/WhiteBoard';
import api from '../../shared/api';
import { useParams } from 'react-router-dom';
import InfoRoomModal from './components/InfoRoomModal/InfoRoomModal';
import WsContext from '../../WsContext';
import toast from '../../utils/toast';
import authHelper from '../../utils/authHelper';
import Chat from './components/Chat/Chat';
import { Peer } from "peerjs";
import useDynamicState from '../../shared/useStateDynamic';
import UsersPanel from './components/UsersPanel/UsersPanel';

const initJSON = `{"version":"5.3.0","objects":[{"type":"textbox","version":"5.3.0","originX":"left","originY":"top","left":282,"top":210,"width":320.47,"height":89.27,"fill":"#000000","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":79,"text":"Draw","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline","minWidth":20,"splitByGrapheme":false}]}`;
const secondJSON = `{"version":"5.3.0","objects":[{"type":"textbox","version":"5.3.0","originX":"left","originY":"top","left":282,"top":210,"width":320.47,"height":89.27,"fill":"#000000","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":79,"text":"Draw","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline","minWidth":20,"splitByGrapheme":false},{"type":"textbox","version":"5.3.0","originX":"left","originY":"top","left":426,"top":337,"width":179.79,"height":89.27,"fill":"#000000","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"fontFamily":"Times New Roman","fontWeight":"normal","fontSize":79,"text":"here","underline":false,"overline":false,"linethrough":false,"textAlign":"left","fontStyle":"normal","lineHeight":1.16,"textBackgroundColor":"","charSpacing":0,"styles":[],"direction":"ltr","path":null,"pathStartOffset":0,"pathSide":"left","pathAlign":"baseline","minWidth":20,"splitByGrapheme":false}]}`;



const BoardPage = () => {
    const [settings, setSettings] = useState();
    const [openInfoRoomModal, setInfoRoomModalOpen] = useState(false)
    const [users, setUsers, usersRef] = useDynamicState([])
    const [membersMouseCoords, setMembersMouseCoords] = useState([])
    const [audioWorks, setAudioWorks] = useState(false)
    const [videoWorks, setVideoWorks] = useState(false)
    const [mediaStream, setMediaStream] = useState(null);
    const wsMessage = useContext(WsContext)

    let { id } = useParams();
    const everySecond = () => {
        api.setUserActive(id, authHelper.getUserId())
    }

    const addPeerConnection = (conn) => {
        window.connections.push(conn)
        conn.on('data', function(data){
            data = JSON.parse(data)
            setMembersMouseCoords(coords => {
                let temp = coords.filter(x=>x.id != conn.peer)
                let targetUser = usersRef.current.filter(x=>x.id == conn.peer)[0]
                let name = '' 
                if(targetUser != undefined) name = targetUser.name
                return [...temp, {
                    id: conn.peer, 
                    name: name,
                    clicked: data.clicked, 
                    x: data.coords.x, 
                    y: data.coords.y
                }]
            })
        });
    }

    const getUserMedia = (audio=false, video=false) => {
        if (mediaStream) {  
            mediaStream.getTracks().forEach(track => track.stop());  
        }  
        
        navigator.mediaDevices.getUserMedia(
            { video: video, audio: audio }).then(
            (stream) => {
                setMediaStream(stream)
                let videoElement = document.getElementById(`video_element_${authHelper.getUserId()}`)
                videoElement.srcObject = stream;
                videoElement.onloadedmetadata = (e) => {
                    videoElement.play();
                };
                users.filter(x => x.id != authHelper.getUserId()).forEach(user => {
                    const call = window.peer.call(user.id, stream);
                    call.on("stream", (remoteStream) => {
                        let videoElement = document.getElementById(`video_element_${user.id}`)
                        videoElement.srcObject = remoteStream;
                        videoElement.onloadedmetadata = (e) => {
                            videoElement.play();
                        };
                    }); 
                });
                setInterval(() => {
                    users.filter(x => x.id != authHelper.getUserId()).forEach(user => {
                        const conn = window.peer.connect(user.id)
                        conn.on('open', function(){
                            addPeerConnection(conn)
                        });   
                    });

                }, 2000)
                window.peer.on("call", (call) => {
                    call.answer(stream); // Answer the call with an A/V stream.
                    call.on("stream", (remoteStream) => {
                        let videoElement = document.getElementById(`video_element_${call.peer}`)
                        videoElement.srcObject = remoteStream;
                        videoElement.onloadedmetadata = (e) => {
                            videoElement.play();
                        };
                    });                            
                }); 
                window.peer.on('connection', addPeerConnection);       
            }
        ).catch((err) => {
            console.error("Failed to get local stream", err);
        });
    }

    useEffect(() => {
        getUserMedia(audioWorks, videoWorks)
    }, [audioWorks, videoWorks])

    useEffect(() => {
        api.addUserToRoom(localStorage.getItem('tutorConnectionUserId'), id).then(resp => {
            setSettings({ contentJSON: initJSON });
            setTimeout(() => {
              setSettings({ contentJSON: secondJSON, viewportTransform: [1, 0, 0, 1, 0, 0] });
            }, 1000);       
            setInfoRoomModalOpen(localStorage.getItem(`userCloseWelcomeModal_${id}`) ? false: true)
            window.peer = new Peer(authHelper.getUserId()
            // , {
            //     host: "beresta.link",
            //     path: "/peer",
            // }
        );
            api.getRoomInfo(id).then(res => {
                setUsers(res.users)
                let initAudio = res.users.filter(u => u.id === authHelper.getUserId())[0].audio
                let initVideo = res.users.filter(u => u.id === authHelper.getUserId())[0].video 
                setAudioWorks(initAudio)
                setVideoWorks(initVideo)
                getUserMedia(initAudio, initVideo)
            })
        })
        const timerId = setInterval(everySecond, 1000)
        window.connections = []
        return () => {clearInterval(timerId)}
    }, []);

    useEffect(() => {
        if(wsMessage?.type === 'add'){
            toast.success(`${wsMessage.userName} присоединился`)
            
            api.getRoomInfo(id)
            .then(res => setUsers(res.users))
        }
        if(wsMessage?.type === 'remove'){
            setMembersMouseCoords(members => members.filter(x => x.name != wsMessage.userName))
            toast.success(`${wsMessage.userName} вышел`)
            
            api.getRoomInfo(id)
            .then(res => setUsers(res.users))
        }
        if(wsMessage?.type === 'rename'){
            toast.success(`"${wsMessage.oldName}" стал называть себя "${wsMessage.newName}"`)
            
            api.getRoomInfo(id)
            .then(res => setUsers(res.users))
        }
    }, [wsMessage])

    return (
        <div>
            {/* <div>{JSON.stringify(membersMouseCoords)}</div> */}
            <WhiteBoard
                roomId={id}
                users={users}
                audioWorks={audioWorks} 
                setAudioWorks={setAudioWorks}
                videoWorks={videoWorks} 
                setVideoWorks={setVideoWorks}
                membersMouseCoords={membersMouseCoords}
                onMouseCoordsChange={(coords, clicked) => {
                    if (window.connections != undefined){
                        window.connections.forEach(conn => {
                            conn.send(JSON.stringify({coords: coords, clicked: clicked})); 
                        });  
                    }
                }}
                setUsers={setUsers}
            />
            <UsersPanel 
            users={users} 
            setUsers={setUsers}
            />
            <InfoRoomModal open={openInfoRoomModal} setOpen={setInfoRoomModalOpen} setUsers={setUsers}/>
        </div>
    )
}

export default BoardPage;