import { act, useContext, useEffect, useRef, useState } from 'react';
import './WhiteBoard.scss'
import api from '../../../../shared/api';
import authHelper from '../../../../utils/authHelper';
import WsContext from '../../../../WsContext';
import useDynamicState from '../../../../shared/useStateDynamic';
import { useWindowSize } from '../../../../utils/windowSize';
import SvgFromPoint from './components/SvgFromPoint/SvgFromPoint';
import toast from '../../../../utils/toast';
import InstrumentPanel from './components/InstrumentPanel/InstrumentPanel';
import pointInRectangle from './components/pointInRectangle';
import jsonParse from './components/jsonParse';
import renderElement from './components/renderElement';
import normalizeZoom from './components/normalizeZoom';
import Cursor from './components/Cursor/Cursor';
import mainLoop from './components/mainLoop';
import onWhiteBoardMouseDown from './components/onWhiteBoardMouseDown';
import getFontSize from './components/getFontSize';

const WhiteBoard = ({
    roomId, 
    membersMouseCoords, 
    onMouseCoordsChange, 
    users, setUsers,
    audioWorks, setAudioWorks,
    videoWorks, setVideoWorks
}) => {
    const [clicked, setClicked, clickedRef] = useDynamicState(false)
    const [elements, setElements, elementsRef] = useDynamicState([])
    const [curveState, setCurveState, curveStateRef] = useDynamicState([])
    const [currentMousePosition, setCurrentMousePosition, currentMousePositionRef] = useDynamicState({x: 0, y: 0})
    const [instrument, setInstrument, instrumentRef] = useDynamicState('pen')
    const [instrumentState, setInstrumentState, instrumentStateRef] = useDynamicState(null)
    const [isLoading, setIsLoading, isLoadingRef] = useDynamicState(false)
    const [startMovePoint, setStartMovePoint, startMovePointRef] = useDynamicState(null)
    const [startSelectPoint, setStartSelectPoint, startSelectPointRef] = useDynamicState(null)
    const [startMovebleElementsCoords, setStartMovebleElementsCoords, startMovebleElementsCoordsRef] = useDynamicState(null)
    const [startResizingElementsSize, setStartResizingElementsSize, startResizingElementsSizeRef] = useDynamicState(null)
    const [zoom, setZoom, zoomRef] = useDynamicState(5)
    const [selectedElementIds, setSelectedElementIds, selectedElementIdsRef] = useDynamicState(null)
    const [selectedAreaData, setSelectedAreaData,selectedAreaDataRef] = useDynamicState(null)
    const [centerCoords, setCenterCoords, centerCoordsRef] = useDynamicState({x: 0, y: 0})
    const [noteFontSize, setNoteFontSize, noteFontSizeRef] = useDynamicState('16px')
    const [zoomIsChanging, setZoomIsChanging] = useState(false)
    const [history, setHistory] = useState([])
    const [reverseHistory, setReverseHistory] = useState([])
    const [width, height] = useWindowSize();

    let normalizedZoom = normalizeZoom(zoomRef.current)

    const wsMessage = useContext(WsContext)
    const penStrokeWidth= 2 * (zoom * 0.25)
    const markerStrokeWidth= 15 * (zoom * 0.25)
    const cursorHeight = 30
    const resizeThreshold = 50

    const [penColorIndex, setPenColorIndex, penColorIndexRef] = useDynamicState(0)
    const [markerColorIndex, setMarkerColorIndex, markerColorIndexRef] = useDynamicState(2)
    const [noteColorIndex, setNoteColorIndex, noteColorIndexRef] = useDynamicState(0)

    const eventsDivRef = useRef(null);  
    useEffect(() => {  
        const setFocusOnBoardTimer = setInterval(() => {
            if (eventsDivRef.current && document.activeElement != null) {  
                if(document.activeElement.nodeName != 'TEXTAREA' && document.activeElement.nodeName != 'INPUT')
                    eventsDivRef.current.focus();
            }      
        }, 100)
        return () => {clearInterval(setFocusOnBoardTimer)}
    }, []);  

    useEffect(() => {
        if (history.length > 50) setHistory(history.slice(-50));   
    }, [history])

    useEffect(() => {
        if (reverseHistory.length > 50) setReverseHistory(reverseHistory.slice(-50));   
    }, [reverseHistory])

    useEffect(() => {
        setNoteFontSize(getFontSize(zoomRef.current))
        setZoomIsChanging(true)
        setTimeout(() => {
            setZoomIsChanging(false)
        }, 800)
    }, [zoom])

    useEffect(() => { 
        if(wsMessage?.type === 'add_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    let temp = elements?.map(x=>x)
                    temp.push({id: wsMessage.id, type: wsMessage.elementType, data: wsMessage.data })
                    return temp
                })
                setCurveState([])
            }
        }
        if(wsMessage?.type === 'remove_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    return elements.filter(x=> !wsMessage.ids.includes(x.id) )
                })                
            }
        }
        if(wsMessage?.type === 'clear'){
            if(wsMessage.roomId === roomId){
                setElements([])                
            }
        }
        if(wsMessage?.type === 'update_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    return elements.map(x=> {
                        if(wsMessage.id === x.id){
                            x.data = wsMessage.data
                        }
                        return x
                    } )
                })                
            }
        }
        if(wsMessage?.type === 'change_elements_order'){
            if(wsMessage.roomId === roomId){
                setElements(els => {
                    let target = els.filter(x=> x.id === wsMessage.elId)[0]
                    let temp = els.filter(x=> x.id !== wsMessage.elId)
                    temp.push(target)
                    return temp
                })               
            }
        }
    }, [wsMessage])

    useEffect(() => {
        const intervalId = setInterval(() => mainLoop(
                                                roomId,
                                                cursorHeight,
                                                resizeThreshold,
                                                elementsRef.current,
                                                currentMousePositionRef.current, 
                                                zoomRef.current, 
                                                clickedRef.current,
                                                instrumentRef.current,
                                                isLoadingRef.current,
                                                startMovebleElementsCoordsRef.current,
                                                startMovePointRef.current,
                                                startResizingElementsSizeRef.current,
                                                curveStateRef.current,
                                                setCurveState,
                                                setIsLoading,
                                                setElements,
                                                setInstrumentState,
                                                penColorIndexRef.current,
                                                markerColorIndexRef.current,
                                                centerCoordsRef.current, 
                                                setCenterCoords,
                                                setHistory,
                                                setReverseHistory,
                                                setSelectedAreaData,
                                                startSelectPointRef.current
                                            ), 1)

        api
        .getBoardElementList(roomId)
        .then(resp=>{
            setElements(resp)
        })
        const preventZoom = (event) => {
            if (event.deltaY) {
                event.preventDefault();
                let newZoom = zoomRef.current
                if(event.deltaY > 0){
                    if(zoomRef.current > 1)
                        newZoom -= 1
                }
                else{
                    if(zoomRef.current < 10)
                        newZoom += 1
                }
                setZoom(newZoom)
            }
        };
    
        window.addEventListener('wheel', preventZoom, { passive: false })
      
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('wheel', preventZoom);
        }
    }, [])

    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let index in items) {
            let item = items[index];
            if (item.kind === 'file') {
                let imageFile = item.getAsFile();
                let fileName = imageFile.name
                api
                .uploadFile(authHelper.getUserId(), roomId, fileName, imageFile, 
                    (currentMousePositionRef.current.x - 2*centerCoordsRef.current.x) / normalizedZoom,
                    (currentMousePositionRef.current.y - 2*centerCoordsRef.current.y) / normalizedZoom)
                .then(resp => {
                    if (resp.imgSrc) {
                        const tempElement={
                            id: resp.id,
                            type: 1,
                            data: JSON.stringify({
                                x: (currentMousePositionRef.current.x - 2*centerCoordsRef.current.x) / normalizedZoom, 
                                y: (currentMousePositionRef.current.y - 2*centerCoordsRef.current.y) / normalizedZoom, 
                                width: resp.width, height: resp.height,
                                imgSrc: resp.imgSrc
                            })
                        }
                        setElements([...elementsRef.current, tempElement])   
                        setHistory(h=>[...h, {action: 'create', elements: [tempElement]}])
                        setReverseHistory([])
                    } else {
                        toast.success(`${resp.fileName} отправлен в чат`)
                    }
                })
            }
            if (item.kind === 'string' && instrumentRef.current === 'selector') {
                item.getAsString((clipboardContent) => {  
                    try {
                        const copiedElements = JSON.parse(clipboardContent);
                        let elementsForCreate = []
                        copiedElements.map(el => {
                            const data = JSON.stringify({
                                ...el.data,
                                x: currentMousePositionRef.current.x - el.data.diffX,
                                y: currentMousePositionRef.current.y - el.data.diffY
                            })
                            api
                            .createBoardElement(authHelper.getUserId(), roomId, el.type, data)
                            .then(res => {
                                const tempElement = {id: res, type: el.type, data: data}
                                setElements(elements => {
                                    let temp = elements?.map(x=>x)
                                    temp.push(tempElement)
                                    return temp
                                })
                                elementsForCreate.push(tempElement)
                            })
                        })
                        setHistory(h=>[...h, {action: 'create', elements: elementsForCreate}])
                        setReverseHistory([])
                    } catch (e) {}  
                })
            }
        }
    };

    const onClickWhiteBoard = (e) => {
        if (instrumentRef.current === 'selector' || instrumentRef.current === 'notes') {
            let el_id = null
            let x, y;
            if (e.type === 'touchmove') {
                const touch = e.touches[0];
                x = touch.clientX - 2*centerCoords.x;
                y = touch.clientY - cursorHeight - 2*centerCoords.y;
            } else {
                x = e.clientX - 2*centerCoords.x;
                y = e.clientY - cursorHeight - 2*centerCoords.y;
            }
            x = x - width/2 + centerCoordsRef.current.x
            y = y - height/2 + centerCoordsRef.current.y
            
            elementsRef.current.filter(el => {
                let data = jsonParse(el.data)
                if(pointInRectangle({x, y}, {x: data.x, y: data.y, width: data.width, height: data.height}, normalizedZoom) || startResizingElementsSizeRef.current)
                    el_id = el.id
                
                // выделение кривых по клику
                let curve = null
                try {curve = jsonParse(data.curve)} catch{}
                if (curve != null && curve.filter(point => 
                    pointInRectangle(
                        {x: currentMousePositionRef.current.x, y: currentMousePositionRef.current.y - cursorHeight}, 
                        {x: point.x-10, y: point.y-10, width: 20, height: 20}, 
                        normalizedZoom
                    )).length > 0) el_id = el.id
            })
            if (instrumentRef.current === 'notes' && instrumentStateRef.current !== 'text' && elementsRef.current.filter(el => el.selected).length === 0) {
                const data = {
                    x: (currentMousePositionRef.current.x - 2*centerCoords.x) / normalizedZoom,
                    y: (currentMousePositionRef.current.y  - 2*centerCoords.y) / normalizedZoom, 
                    width: 100,
                    height: 100,
                    text: '',
                    degree: Math.floor(Math.random() * 7)-3,
                    color: noteColorIndex
                }
                api
                .createBoardElement(authHelper.getUserId(), roomId, 3, data)
                .then(res => {
                    const tempElement = {id: res, type: 3, data: JSON.stringify(data)}
                    setElements(elements => {
                        let temp = elements?.map(x=>x)
                        temp.push(tempElement)
                        return temp
                    })
                    setHistory(h=>[...h, {action: 'create', elements: [tempElement]}])
                    setReverseHistory([])
                })
            }
            setElements(els => {
                let target = els.filter(x=> x.id === el_id)[0]
                if(target == undefined)
                    return els.map(x=>{ return {...x, selected: instrumentRef.current === 'notes'? false: x.selected}})
                let temp = els.filter(x=> x.id !== el_id)
                temp.push({...target, selected: instrumentRef.current === 'selector' || target.type === 3})
                
                const topElementId = elementsRef.current[elementsRef.current.length-1].id 

                if (target.id !== topElementId)
                    api
                    .changeElementsOrder(authHelper.getUserId(), el_id)
                    .then(res => {
                        setHistory(h=>[...h, {action: 'changeOrder', element: topElementId}])
                        setReverseHistory([])
                    })

                return temp
            })
            setStartResizingElementsSize(null)
        }
    }

    const onMove = e => {
        e.preventDefault()
        let x, y;
        if (e.type === 'touchmove') {
            const touch = e.touches[0];
            x = touch.clientX;
            y = touch.clientY;
        } else {
            x = e.clientX;
            y = e.clientY;
        }
        x = x - width/2 + centerCoordsRef.current.x
        y = y - height/2 + centerCoordsRef.current.y
        setCurrentMousePosition({x: x, y: y})
        onMouseCoordsChange({
            x: x / normalizedZoom + centerCoordsRef.current.x, 
            y: y / normalizedZoom + centerCoordsRef.current.y
        }, clicked)
    }

    const onDown = e => onWhiteBoardMouseDown (
        e,
        width,
        height,
        centerCoordsRef.current,
        normalizedZoom,
        resizeThreshold/normalizedZoom,
        elementsRef.current,
        setClicked,
        setStartMovePoint,
        setStartMovebleElementsCoords,
        setStartResizingElementsSize,
        setSelectedElementIds,
        cursorHeight,
        setStartSelectPoint
    )

    const onUp = (e) => {
        e.preventDefault()
        setClicked(false)
        setStartMovePoint(null)
        setStartSelectPoint(null)
        setSelectedAreaData(null)

        if (selectedAreaDataRef.current) {
            setElements(els => els.map(el => {return {...el, hover: false, selected: el.hover}}))
        }  

        if (selectedElementIdsRef.current != null) {
            let oldEls = []
            selectedElementIdsRef.current.map(id => 
                api
                .updateBoardElement( 
                    id, 
                    elementsRef.current.filter(el => el.id === id)[0]?.data, 
                    authHelper.getUserId())
                .then(oldEl => oldEls.push(oldEl))
            )
            setHistory(h=>[...h, 
                {
                    action: 'update', 
                    elements: oldEls
                }
            ])
            setReverseHistory([])
            setSelectedElementIds(null)
        }
    }
    
    return (<>
        <div 
        onPaste={handlePaste} 
        className='whiteboard' 
        style={{
            width: `${width}px`, 
            height: `${height}px`, 
            backgroundSize: `${(normalizedZoom) * 200}px`,
            backgroundPosition: `top ${centerCoordsRef.current.y}px left ${centerCoordsRef.current.x}px`
        }}>
            {
                zoomIsChanging &&
                <div style={{top: height/2-50, left: width/2,}} className='zoom'>
                    {zoom/10*100}%
                </div>
            }
        {/* <div style={{
            top: height/2,
            left: width/2,
            height: '10px',
            width: '10px',
            background: 'red',
            borderRadius: '50%',
            position: 'absolute'
        }}/> */}
            {elementsRef.current.map(element => 
                renderElement(
                    element,
                    width,
                    height,
                    normalizedZoom,
                    noteFontSizeRef.current,
                    centerCoordsRef.current,
                    penStrokeWidth,
                    markerStrokeWidth,
                    instrumentRef.current,
                    elementsRef.current,
                    setElements,
                    instrumentStateRef.current
                ))
            }
            {membersMouseCoords.map(membersMouseCoord => <div style={{
                position: 'absolute', 
                top: membersMouseCoord.y * normalizedZoom + height/2,
                left: membersMouseCoord.x * normalizedZoom + width/2,
                opacity: 0.5
                }}>
                <div 
                className='member-cursor'
                style={{
                    backgroundColor: membersMouseCoord.clicked ? 'black' : 'white'
                }}/>
                <div>{membersMouseCoord.name}</div>
            </div>)}
            {curveState != undefined && curveState.length > 0 ? <SvgFromPoint 
            windowSize={{width, height}}
            zoom={normalizedZoom}
            centerCoords={centerCoordsRef.current}
            opacity={instrument == 'pen'? 0.5: 0.25}
            instrument={instrumentRef.current}
            colorIndex={instrumentRef.current == 'pen' ? penColorIndexRef.current : markerColorIndexRef.current} 
            strokeWidth={instrumentRef.current == 'pen' ? penStrokeWidth : markerStrokeWidth} 
            pointsData={curveState} /> : ''}
            {
                selectedAreaDataRef.current &&
                <div 
                style={{
                    left: `${selectedAreaDataRef.current.x + width/2 + centerCoords.x}px`,
                    top: `${selectedAreaDataRef.current.y + height/2 + centerCoords.y}px`,
                    width: `${selectedAreaDataRef.current.width}px`,
                    height: `${selectedAreaDataRef.current.height}px`,
                }}
                className='selected-area' />
            }
            <Cursor 
            instrument={instrument} 
            instrumentState={instrumentState} 
            centerCoords={centerCoordsRef.current}
            currentMousePosition={currentMousePositionRef.current} 
            cursorHeight={cursorHeight} 
            height={height} 
            width={width}/>
            <div 
            className='events-div'
            style={{
                width: `${width}px`, 
                height: `${height}px`, 
                position: 'absolute', 
            }}
            onMouseDown={onDown}
            onMouseUp={onUp}
            onClick={onClickWhiteBoard}
            onMouseMove={onMove}
            onTouchStart={onDown}
            onTouchEnd={onUp}
            onTouchMove={onMove}
            ref={eventsDivRef}  
            tabIndex={0}
            onKeyDown={e => {
                let stepForCoords = e.shiftKey ? 50 : 10;
                
                if ((e.ctrlKey && e.code === 'KeyZ' && history.length > 0) || (e.ctrlKey && e.code === 'KeyY' && reverseHistory.length > 0)) {
                    const action = e.code === 'KeyZ' ? history.pop(): reverseHistory.pop()
                    if (action.action === 'create') {
                        if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'delete', elements: action.elements }])
                        if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'delete', elements: action.elements }])
                        action.elements.map(element => 
                            api
                            .deleteBoardElements(authHelper.getUserId(), roomId, [element.id])
                            .then(res => {
                                setElements(elements => {
                                    let temp = elements?.map(x=>x)
                                    temp = temp.filter(x => element.id!==x.id)
                                    return temp
                                })
                            })
                        )
                    }
                    if (action.action === 'delete') {
                        if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'create', elements: action.elements }])
                        if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'create', elements: action.elements }])
                        action.elements.map(element => 
                            api
                            .createBoardElement(authHelper.getUserId(), roomId, element.type, element.data, element.id)
                            .then(res => {
                                setElements(elements => {
                                    let temp = elements?.map(x=>x)
                                    let data = element.data
                                    temp.push({id: res, type: element.type, data: typeof data == 'string'? data: JSON.stringify(data)})
                                    return temp
                                })
                            })
                        )
                    }
                    if (action.action === 'update') {
                        let oldEls = []
                        action.elements.map(element => 
                            api
                            .updateBoardElement( 
                                element.id, 
                                element.data, 
                                authHelper.getUserId())
                            .then(oldEl => {
                                oldEls.push(oldEl)
                                setElements(elements => {
                                    let temp = elements?.map(x=>{
                                        if(x.id === element.id){
                                            x.data = element.data
                                        }
                                        return x
                                    })
                                    return temp
                                })}))
                        if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'update', elements: oldEls }])
                        if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'update', elements: oldEls }])
                    }
                    if (action.action === 'clearBoard') {
                        if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'createBoard'}])
                        if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'createBoard'}])
                        action.elements.map(el => 
                            api
                            .createBoardElement(authHelper.getUserId(), roomId, el.type, el.data, el.id)
                            .then(res => {
                                setElements(elements => {
                                    let temp = elements?.map(x=>x)
                                    let data = el.data
                                    temp.push({id: res, type: el.type, data: typeof data == 'string'? data: JSON.stringify(data)})
                                    return temp
                                })
                            })
                        )
                    }
                    if (action.action === 'createBoard') {
                        api
                        .clearBoard(authHelper.getUserId(), roomId)
                        .then(() => {
                            setElements([])
                            if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'clearBoard', elements: elements}])
                            if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'clearBoard', elements: elements}])
                        })
                    }
                    if (action.action === 'changeOrder') {
                        const topElementId = elementsRef.current[elementsRef.current.length-1].id 
                        if (e.code === 'KeyZ') setReverseHistory(h=>[...h, {action: 'changeOrder', element: topElementId }])
                        if (e.code === 'KeyY') setHistory(h=>[...h, {action: 'changeOrder', element: topElementId }])
                        api
                        .changeElementsOrder(authHelper.getUserId(), action.element)
                        .then(res => {
                            setElements(els => {
                                let target = els.filter(x=> x.id === action.element)[0]
                                let temp = els.filter(x=> x.id !== action.element).map(el => {return({...el, selected: false})})
                                temp.push({...target, selected: true})
                                return temp
                            })
                        })
                    }
                }
                else if (e.ctrlKey && e.code === 'KeyA') {e.preventDefault(); setElements(els => els.map(el => {return ({...el, selected: true})}))}
                else if (e.ctrlKey && (e.code === 'KeyC' || e.code === 'KeyX')) {
                    const elementsForCopy = elementsRef.current.filter(x => x.selected)
                    const topElement = elementsForCopy.reduce((max, current) => {  
                        return current.data.y > max.data.y ? current : max;  
                       })
                    const maxY = JSON.parse(topElement.data).y
                    const leftElement = elementsForCopy.reduce((min, current) => {  
                        return current.data.x < min.data.x ? current : min;  
                       }); 
                    const minX = JSON.parse(leftElement.data).x
                    const jsonString = JSON.stringify(elementsForCopy.map(el => {
                        const data = JSON.parse(el.data)
                        return({...el, data: {...data, diffX: minX-data.x, diffY: maxY-data.y}})
                    }));
                    navigator.clipboard.writeText(jsonString)

                    if (e.code === 'KeyX') {
                        let elementIdsForDelete = elementsForCopy.map(x=>x.id)
                        api
                        .deleteBoardElements(authHelper.getUserId(), roomId, elementIdsForDelete)
                        .then(res => {
                            setElements(elements => {
                                let temp = elements?.map(x=>x)
                                temp = temp.filter(x=> !elementIdsForDelete.includes(x.id))
                                return temp
                            })
                        })
                        setHistory(h=>[...h, {action: 'delete', elements: elementsForCopy}])
                        setReverseHistory([])
                    }
                }
                else if (e.ctrlKey && e.key === 'ArrowUp') setZoom(z => z < 10 ? z+=1: z)
                else if (e.ctrlKey && e.key === 'ArrowDown') setZoom(z => z > 1 ? z-=1: z)
                else switch (e.key) { 
                    case 'ArrowUp':  
                        setCenterCoords({x: centerCoordsRef.current.x, y: centerCoordsRef.current.y + stepForCoords})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x,
                            y: currentMousePositionRef.current.y + stepForCoords,
                        })
                        break;  
                    case 'ArrowDown':  
                        setCenterCoords({x: centerCoordsRef.current.x, y: centerCoordsRef.current.y - stepForCoords})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x,
                            y: currentMousePositionRef.current.y - stepForCoords,
                        })
                        break;  
                    case 'ArrowLeft':  
                        setCenterCoords({x: centerCoordsRef.current.x + stepForCoords, y: centerCoordsRef.current.y})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x + stepForCoords,
                            y: currentMousePositionRef.current.y,
                        })
                        break;  
                    case 'ArrowRight':  
                        setCenterCoords({x: centerCoordsRef.current.x - stepForCoords, y: centerCoordsRef.current.y}) 
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x - stepForCoords,
                            y: currentMousePositionRef.current.y,
                        })
                        break;  
                    case 'Delete':
                        let elementsForDelete = elementsRef.current.filter(el => el.selected)
                        let elementIdsForDelete = elementsForDelete.map(el => el.id)
                        api
                        .deleteBoardElements(authHelper.getUserId(), roomId, elementIdsForDelete)
                        .then(res => {
                            setElements(elements => {
                                let temp = elements?.map(x=>x)
                                temp = temp.filter(x=> !elementIdsForDelete.includes(x.id))
                                return temp
                            })
                            setHistory(h=>[...h, {action: 'delete', elements: elementsForDelete}])
                            setReverseHistory([])
                        })
                        break;
                    default:  
                        break; 
            }}}
            />
            {
                users.length > 0 && 
                <InstrumentPanel 
                audioWorks={audioWorks}
                setAudioWorks={setAudioWorks}
                videoWorks={videoWorks} 
                setVideoWorks={setVideoWorks}
                instrument={instrument} 
                setInstrument={setInstrument}             
                setElements={setElements} 
                penColorIndex={penColorIndex} 
                setPenColorIndex={setPenColorIndex}
                markerColorIndex={markerColorIndex}
                setMarkerColorIndex={setMarkerColorIndex}
                noteColorIndex={noteColorIndex}
                setNoteColorIndex={setNoteColorIndex}
                setUsers={setUsers}
                setCleaningBoardToHistory={() => {
                    setHistory(h=>[...h, {action: 'clearBoard', elements: elements}])
                    setReverseHistory([])
                }}
                />
            }
        </div>
    </>)
}

export default WhiteBoard;