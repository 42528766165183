import { useEffect } from 'react'
import { markerColors, noteColors, penColors } from '../WhiteBoard/components/colors'
import './ColorPanel.scss'

const ColorPanel = ({
    instrument, 
    penColorIndex,
    setPenColorIndex,
    markerColorIndex,
    setMarkerColorIndex,
    noteColorIndex,
    setNoteColorIndex
}) => {
    const colors = 
        instrument==='pen'? penColors: 
        instrument==='marker'? markerColors:
        instrument==='notes'? noteColors: [] 
    return (
        <div className='color-panel'>
            {
                colors.length != 0 &&                
                <div className='colors'>
                    {
                        colors.map((color, index) => 
                            <div 
                            style={{background: color}} 
                            className={
                                `color 
                                ${(instrument === 'pen' && penColorIndex === index || 
                                    instrument === 'marker' && markerColorIndex === index ||
                                    instrument === 'notes' && noteColorIndex===index
                                    ) ? 'active': ''}
                                `} 
                            onClick={() => {
                                if (instrument === 'pen') setPenColorIndex(index)
                                else if (instrument === 'marker') setMarkerColorIndex(index)
                                else if (instrument === 'notes') setNoteColorIndex(index)
                            }}    
                            />
                        )
                    }
                </div>
            }
        </div>
    )
}

export default ColorPanel;