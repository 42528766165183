import jsonParse from "./jsonParse"
import pointInRectangle from "./pointInRectangle"

const onWhiteBoardMouseDown = (
    e,
    width,
    height,
    centerCoords,
    normalizedZoom,
    resizeThreshold,

    elements,

    setClicked,
    setStartMovePoint,
    setStartMovebleElementsCoords,
    setStartResizingElementsSize,
    setSelectedElementIds,

    cursorHeight,

    setStartSelectPoint
) => {
    let x, y;
    if (e.type === 'touchmove') {
        const touch = e.touches[0];
        x = touch.clientX;
        y = touch.clientY;
    } else {
        x = e.clientX;
        y = e.clientY;
    }
    let point = {x: x - width/2 - centerCoords.x, y: y - height/2 - centerCoords.y}
    
    if (e.button === 1) {
        setStartMovePoint(point)
        return
    }

    setClicked(true)
    let filteredElementsForMove = elements.map(x=>x).filter(el => {
        let data = jsonParse(el.data)
        let curve = null
        try {curve = jsonParse(data.curve)} catch{}
        return el.selected && (pointInRectangle(
            point,
            {x: data.x, y: data.y, width: data.width, height: data.height + cursorHeight + 5}, normalizedZoom) || (
            curve != null && curve.filter(p =>
                pointInRectangle(
                    {x: point.x, y: point.y-cursorHeight},
                    {x: p.x-10, y: p.y-10, width: 20, height: 20},
                    normalizedZoom
                )).length > 0
        ))})

    if (filteredElementsForMove.length > 0) filteredElementsForMove = elements.filter(el => el.selected) 
    
    let filteredElementsForResize = elements.filter(el => {
        let data = jsonParse(el.data)
        return (el.type === 1 || el.type === 3) && el.selected &&  pointInRectangle(
            point, 
            {x: data.x + data.width - resizeThreshold/2, y: data.y + data.height - resizeThreshold/2, width: resizeThreshold, height: resizeThreshold}, normalizedZoom)}) 

    if (filteredElementsForMove.length > 0 || filteredElementsForResize.length > 0) {
        setStartMovePoint(point)
        if(filteredElementsForMove.length > 0) {
            setSelectedElementIds(filteredElementsForMove.map(x => x.id))
            setStartMovebleElementsCoords(filteredElementsForMove.map(el => {
                let curve = null
                let data = jsonParse(el.data)
                try {curve = jsonParse(data.curve)} catch{}

                if (curve) return ({id: el.id, curve: curve})
                return({id: el.id, x: data.x, y: data.y})
            }))
        }
        if(filteredElementsForResize.length > 0) {
            setSelectedElementIds(filteredElementsForResize.map(x => x.id))
            const element = filteredElementsForResize[0]
            let data = jsonParse(element.data)
            setStartResizingElementsSize([{id: element.id, width: data.width, height: data.height}])
        }
    } else {
        setStartSelectPoint(point)
        setStartMovePoint(null)
        setStartMovebleElementsCoords(null)
    }
}

export default onWhiteBoardMouseDown;